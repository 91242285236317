import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { redirectToExternalPortal } from '../../externalRedirectHelpers';
import { TextTrans } from '../common/Text';
import { useSettings } from '../../hooks/useSettings';

const Container = styled(TextTrans)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

type Props = {
  shopOrderId?: string;
};

function ExternalRedirect(props: Props) {
  const { shopOrderId } = props;
  const { externalPortalLink } = useSettings();

  useEffect(() => {
    redirectToExternalPortal(getExternalPortalLink(shopOrderId, externalPortalLink));
    // eslint-disable-next-line
  }, []);

  return <Container>Redirecting to external portal</Container>;
}

function getExternalPortalLink(shopOrderId?: string, externalPortalLink?: string): string {
  return `${externalPortalLink}${shopOrderId}`;
}

export default React.memo(ExternalRedirect);
