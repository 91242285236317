import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';

import { useSettings } from '../../hooks/useSettings';
import Heading2 from '../common/Heading2';
import Text from '../common/Text';
import Button from '../common/Button';
import Heading3 from '../common/Heading3';
import { OverviewReturnItem } from '../../types';
import colors from '../common/colors';

const Counter = styled('div')`
  margin: 4px 0 16px 0;
`;

const Separator = styled('div')`
  box-sizing: border-box;
  border-top: 1px solid ${colors.lightGrey};
  margin-bottom: 20px;
`;

type Props = {
  hasError?: boolean;
  isLoading?: boolean;
  selectedItems: OverviewReturnItem[];
  onButtonClick: () => void;
  isDesktop: boolean;
  buttonText: string;
  buttonName: string;
};

function ItemsCountWithAction(props: Props) {
  const { selectedItems, onButtonClick, isLoading, hasError, isDesktop, buttonText, buttonName } = props;
  const { accentColor } = useSettings();
  const { t } = useTranslation();
  const Heading = isDesktop ? Heading2 : Heading3;
  const selectedItemsCount = useMemo(() => getSelectedItemsCount(selectedItems), [selectedItems]);

  return (
    <>
      {!isDesktop && <Separator />}
      <Heading>
        <Trans>overview_createReturnHeading</Trans>
      </Heading>
      <Counter data-testid="items-counter">
        <Text secondary>{t('overview_selectedItemsCount', { count: selectedItemsCount })}</Text>
      </Counter>
      <Button
        name={buttonName}
        fullWidth
        color={accentColor}
        disabled={selectedItemsCount === 0}
        onClick={onButtonClick}
        isLoading={isLoading}
        error={hasError}
      >
        {hasError ? t('tryAgainButton') : t(buttonText)}
      </Button>
    </>
  );
}

function getSelectedItemsCount(items: OverviewReturnItem[]): number {
  return items.reduce((acc, item) => {
    if (item.totalQuantity > 1) {
      return acc + (item.quantity || 0);
    }

    return acc + 1;
  }, 0);
}

export default React.memo(ItemsCountWithAction);
