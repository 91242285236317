import React from 'react';

import DesktopLayout from './DesktopLayout';
import TabletLayout from './TabletLayout';
import MobileLayout from './MobileLayout';
import { LayoutWithActionProps } from './types';
import useReturnPortalSize from '../../hooks/useReturnPortalSize';

const layouts = {
  s: MobileLayout,
  m: TabletLayout,
  l: (props: LayoutWithActionProps) => <DesktopLayout {...props} size="l" />,
  xl: (props: LayoutWithActionProps) => <DesktopLayout {...props} size="xl" />,
};

function LayoutWithAction(props: LayoutWithActionProps) {
  const size = useReturnPortalSize();
  const Layout = layouts[size];

  return <Layout {...props} />;
}

export default React.memo(LayoutWithAction);
