import React from 'react';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';

import Column from '../common/layout/Column';
import Heading3 from '../common/Heading3';

const Header = styled('div')`
  margin-bottom: 20px;
`;

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

function WhatsNext(props: Props) {
  return (
    <>
      <Header>
        <Heading3>
          <Trans>nextStepsHeading</Trans>
        </Heading3>
      </Header>
      <Column spacing={24}>{props.children}</Column>
    </>
  );
}

export default React.memo(WhatsNext);
