import React from 'react';

import { ReturnRequest } from '../../types';
import { ConfirmationState, ReturnConfirmationViewProps } from './types';
import NormalConfirmation from './NormalConfirmationView';
import ExternalConfirmation from './ExternalConfirmationView';
import KeepItemsConfirmation from './KeepItemsConfirmationView';
import PendingConfirmation from './PendingConfirmationView';

const views: Record<ConfirmationState, typeof NormalConfirmation> = {
  normal: NormalConfirmation,
  keepItems: KeepItemsConfirmation,
  external: ExternalConfirmation,
  pending: PendingConfirmation,
};

function ReturnConfirmationView(props: ReturnConfirmationViewProps) {
  const confirmationState = getConfirmationState(props.returnRequest);
  const ConfirmationView = views[confirmationState];

  return <ConfirmationView {...props} />;
}

function getConfirmationState(returnRequest: ReturnRequest): ConfirmationState {
  const { state, workflowType } = returnRequest;

  if (state === 'done') {
    if (workflowType === 'normal') {
      return 'normal';
    } else if (workflowType === 'keepItems') {
      return 'keepItems';
    } else if (workflowType === 'external') {
      return 'external';
    }
  }

  return 'pending';
}

export default React.memo(ReturnConfirmationView);
