import React, { useCallback, useState } from 'react';

import PageLayoutWithLanguageSelector from '../page-layout/PageLayoutWithLanguageSelector';
import ProductsSelection from './ProductsSelection';
import Switch from '../common/Switch';
import NoEligibleItems from '../invalid-returns/NoEligibleItems';
import ReturnExpired from '../invalid-returns/ReturnExpired';
import ReturnNotSupported from '../invalid-returns/ReturnNotSupported';
import ReturnAlreadyCreated from '../invalid-returns/ReturnAlreadyCreated';
import ReturnCountryMismatch from '../invalid-returns/ReturnCountryMismatch';
import useOrder from '../../hooks/useOrder';
import useReturnCarrierServices from '../../hooks/useReturnCarrierServices';
import ExternalRedirect from './ExternalRedirect';
import useOverviewState from '../../hooks/useOverviewState';
import useReturnRequest from '../../hooks/useReturnRequest';
import useReturnRequestActions from '../../hooks/useReturnRequestActions';
import ConfirmationRedirect from './ConfirmationRedirect';
import CarrierSelection from '../carrier-selection/CarrierSelection';
import useNextButtonAction from '../../hooks/useNextButtonAction';
import ErrorPageWithLayout from '../error/ErrorPageWithLayout';
import { ItemToSubmit } from '../../types';
import { isApiError, getApiErrorCode } from '../../apiHelpers';

function ReturnOverviewPage() {
  const [order, orderLoading, orderError] = useOrder();
  const [returnCarrierServices, returnCarrierServicesLoading, returnCarrierServicesError] = useReturnCarrierServices();
  const [returnRequest, returnRequestLoading, returnRequestError] = useReturnRequest(order?.returnRequestId);
  const { submitState, create, update } = useReturnRequestActions(order?.returnRequestId);
  const [itemsToSubmit, setItemsToSubmit] = useState<ItemToSubmit[]>([]);
  const { onNextButtonClickAction, isNextActionLoading, nextActionError } = useNextButtonAction({
    returnCarrierServices,
    create,
    setItemsToSubmit,
  });

  const overviewState = useOverviewState({
    order,
    returnRequest,
    itemsToSubmit,
    submitState,
    returnCarrierServices,
  });

  const onCarrierSelectionButtonClick = useCallback(carrierService => create(itemsToSubmit, carrierService), [
    create,
    itemsToSubmit,
  ]);

  const onProductsOverviewButtonClick = useCallback(items => create(items, returnCarrierServices?.[0]), [
    create,
    returnCarrierServices,
  ]);

  const isButtonLoading = submitState.isPending || isNextActionLoading;
  const hasButtonError = isApiError(submitState.error) || isApiError(nextActionError);
  const pageError = orderError || returnRequestError || returnCarrierServicesError;

  if (pageError) {
    return <ErrorPageWithLayout errorCode={getApiErrorCode(pageError)} />;
  }

  return (
    <PageLayoutWithLanguageSelector loading={orderLoading || returnRequestLoading || returnCarrierServicesLoading}>
      <Switch selected={overviewState}>
        <ProductsSelection
          key="create"
          order={order}
          returnRequest={returnRequest}
          isButtonLoading={isButtonLoading}
          hasButtonError={hasButtonError}
          onButtonClick={onProductsOverviewButtonClick}
        />
        <ProductsSelection
          key="update"
          order={order}
          returnRequest={returnRequest}
          isButtonLoading={isButtonLoading}
          hasButtonError={hasButtonError}
          onButtonClick={update}
        />
        <ProductsSelection
          key="next"
          buttonText="nextButton"
          buttonName="overviewNext"
          order={order}
          returnRequest={returnRequest}
          isButtonLoading={isButtonLoading}
          hasButtonError={hasButtonError}
          onButtonClick={onNextButtonClickAction}
        />
        <CarrierSelection
          key="carrier-selection"
          order={order}
          submitState={submitState}
          onSubmit={onCarrierSelectionButtonClick}
          returnCarrierServices={returnCarrierServices}
        />
        <ReturnExpired key="expired" order={order} />
        <NoEligibleItems key="no-eligible-items" order={order} />
        <ReturnNotSupported key="return-not-supported" order={order} />
        <ReturnAlreadyCreated key="return-already-created" order={order} />
        <ReturnCountryMismatch key="order-country-mismatch" order={order} />
        <ConfirmationRedirect key="confirmation-redirect" returnRequest={returnRequest} submitState={submitState} />
        <ExternalRedirect key="external-redirect" shopOrderId={order?.shopOrderId} />
      </Switch>
    </PageLayoutWithLanguageSelector>
  );
}

export default React.memo(ReturnOverviewPage);
