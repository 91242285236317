import axios from 'axios';
import { getAuth } from './hooks/useAuth';
import {
  Order,
  ReturnRequest,
  ReturnPortal,
  Auth,
  CreateReturnData,
  UpdateReturnData,
  SubsequentReturnShipment,
  ReturnCarrierService,
  WorkflowType,
} from './types';

function getAuthHeaders() {
  const auth = getAuth();

  return {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  };
}

const returnApi = axios.create({
  baseURL: process.env.REACT_APP_RETURN_PORTAL_API_URL || 'http://localhost:7777',
  transformResponse: [response => JSON.parse(response)],
});

export async function login(orderId: string, email: string, shopId: number | undefined): Promise<Auth> {
  const { data } = await returnApi.post<Auth>(`/login`, { shopOrderId: orderId, email, shopId });
  return data;
}

export async function getReturnPortalByHash(hash: string, language?: string) {
  const { data } = await returnApi.get<ReturnPortal>(`/return-portals/${hash}`, { params: { language } });
  return data;
}

export async function getReturnPortalByBrandedUrl(subdomain: string, route: string) {
  const { data } = await returnApi.get<ReturnPortal>('/return-portals', { params: { subdomain, route } });
  return data;
}

export async function getOrder(): Promise<Order> {
  const { data } = await returnApi.get('/order', getAuthHeaders());

  return data;
}

export async function getWorkflowType(payload: CreateReturnData): Promise<{ workflowType: WorkflowType }> {
  const { data } = await returnApi.post('/return-workflow-type', payload, getAuthHeaders());
  return data;
}

export async function createReturn(payload: CreateReturnData): Promise<ReturnRequest> {
  const { data } = await returnApi.post<ReturnRequest>('/returns', payload, getAuthHeaders());
  return data;
}

export async function createExternalReturn(payload: CreateReturnData): Promise<ReturnRequest> {
  const { data } = await returnApi.post<ReturnRequest>('/returns/external', payload, getAuthHeaders());
  return data;
}

export async function updateReturn(id: number, payload: UpdateReturnData): Promise<ReturnRequest> {
  const { data } = await returnApi.patch<ReturnRequest>(`/returns/${id}`, payload, getAuthHeaders());
  return data;
}

export async function getReturnRequest(id: number): Promise<ReturnRequest> {
  const { data } = await returnApi.get<ReturnRequest>(`/returns/${id}`, getAuthHeaders());

  return data;
}

export async function createSubsequentReturnShipment(): Promise<SubsequentReturnShipment> {
  const { data } = await returnApi.post('/return-shipments', {}, getAuthHeaders());

  return data;
}

export async function getReturnCarrierServices(country?: string): Promise<ReturnCarrierService[]> {
  const { data } = await returnApi.get('/return-carrier-services', {
    ...getAuthHeaders(),
    params: { country },
  });

  return data;
}
