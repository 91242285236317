import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import useReturnPortalHash from './useReturnPortalHash';
import useUnauthorizedRedirect from './useUnauthorizedRedirect';
import { useSettings } from './useSettings';
import { getWorkflowType } from '../api';
import { ItemToSubmit, ReturnCarrierService } from '../types';

type Params = {
  returnCarrierServices?: ReturnCarrierService[];
  create: (items: ItemToSubmit[]) => void;
  setItemsToSubmit: (items: ItemToSubmit[]) => void;
};

type Result = {
  onNextButtonClickAction: (items: ItemToSubmit[]) => void;
  nextActionError?: Error;
  isNextActionLoading: boolean;
};

function useNextButtonAction(params: Params): Result {
  const { create, setItemsToSubmit, returnCarrierServices = [] } = params;
  const hash = useReturnPortalHash();
  const { isKeepItemsEnabled } = useSettings();
  const [items, setItems] = useState<ItemToSubmit[]>([]);

  const [state, fetchWorkflowType] = useAsyncFn(items => getWorkflowType({ items, hash }), [hash]);
  const { value, error, loading } = state;

  useUnauthorizedRedirect(error);

  useEffect(() => {
    if (value) {
      const nextAction = value.workflowType === 'keepItems' ? create : setItemsToSubmit;

      nextAction(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onNextButtonClickAction = (itemToSubmit: ItemToSubmit[]) => {
    if (isKeepItemsEnabled && returnCarrierServices.length > 1) {
      setItems(itemToSubmit);
      fetchWorkflowType(itemToSubmit);
    } else {
      setItemsToSubmit(itemToSubmit);
    }
  };

  return {
    onNextButtonClickAction,
    nextActionError: error,
    isNextActionLoading: loading,
  };
}

export default useNextButtonAction;
