import React from 'react';
import styled from '@emotion/styled';

import Text from '../common/Text';
import Heading3 from '../common/Heading3';
import colors from '../common/colors';

const Container = styled('div')`
  height: 100%;
  min-width: 0; // important for create return button maximum size
  display: flex;
  flex-direction: column;
`;

const Header = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Content = styled('div')`
  margin-left: 48px;
`;

const Children = styled('div')`
  margin-top: 16px;
`;

const Circle = styled('div')`
  display: flex;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${colors.smoke};
  margin-right: 16px;
`;

type Props = {
  index: number;
  title: string;
  description: string;
  children?: React.ReactNode;
};

function WhatsNextItem(props: Props) {
  const { index, title, description, children } = props;

  return (
    <Container>
      <Header>
        <Circle>
          <Heading3>{index.toString()}</Heading3>
        </Circle>
        <Heading3>{title}</Heading3>
      </Header>
      <Content>
        <Text secondary>{description}</Text>
        {children && <Children>{children}</Children>}
      </Content>
    </Container>
  );
}

export default React.memo(WhatsNextItem);
