import React from 'react';
import styled from '@emotion/styled';

import colors from '../common/colors';
import { LayoutWithActionProps } from './types';
import MobilePadding from '../paddings/MobilePadding';
import StickyButtonContainer from './StickyButtonContainer';

const BodyContainer = styled(MobilePadding)`
  background-color: ${colors.white};
  box-sizing: border-box;
  padding-bottom: 32px;
`;

function MobileLayout(props: LayoutWithActionProps) {
  const { heading, info, content, button } = props;

  return (
    <>
      <BodyContainer>
        {heading}
        {info}
        {content}
      </BodyContainer>
      <StickyButtonContainer>
        <MobilePadding>{button}</MobilePadding>
      </StickyButtonContainer>
    </>
  );
}

export default React.memo(MobileLayout);
