import { Draft } from 'immer';
import { useImmerReducer } from 'use-immer';

import { ItemToSubmit, ReturnCarrierService, ReturnRequest } from '../types';
import useReturnPortalHash from './useReturnPortalHash';
import { createExternalReturn, createReturn, updateReturn } from '../api';
import { useSettings } from './useSettings';
import useUnauthorizedRedirect from './useUnauthorizedRedirect';

type SubmitPendingAction = {
  type: 'SUBMIT_PENDING';
};

type SubmitFulfilledAction = {
  type: 'SUBMIT_FULFILLED';
  returnRequest: ReturnRequest;
};

type SubmitRejectedAction = {
  type: 'SUBMIT_REJECTED';
  error: Error;
};

type Action = SubmitPendingAction | SubmitFulfilledAction | SubmitRejectedAction;

export type SubmitState = {
  submittedReturnRequest?: ReturnRequest;
  isPending: boolean;
  error: Error | null;
};

const initialState: SubmitState = {
  isPending: false,
  error: null,
};

function returnRequestActionsReducer(state: Draft<SubmitState>, action: Action): void | SubmitState {
  switch (action.type) {
    case 'SUBMIT_PENDING':
      state.isPending = true;
      state.error = null;
      break;

    case 'SUBMIT_FULFILLED':
      state.isPending = false;
      state.submittedReturnRequest = action.returnRequest;
      break;

    case 'SUBMIT_REJECTED':
      state.isPending = false;
      state.error = action.error;
      break;

    default:
      return state;
  }
}

type Result = {
  submitState: SubmitState;
  create: (items: ItemToSubmit[], carrierService?: ReturnCarrierService) => void;
  update: (items: ItemToSubmit[]) => void;
};

function useReturnRequestActions(returnRequestId?: number | null): Result {
  const [submitState, dispatch] = useImmerReducer(returnRequestActionsReducer, initialState);

  const hash = useReturnPortalHash();
  const { isExternalRedirectEnabled } = useSettings();
  useUnauthorizedRedirect(submitState.error);

  const create = async (items: ItemToSubmit[], carrierService?: ReturnCarrierService) => {
    dispatch({ type: 'SUBMIT_PENDING' });

    try {
      const submitReturn = isExternalRedirectEnabled ? createExternalReturn : createReturn;
      const returnRequest = await submitReturn({ hash, items, carrierService });

      dispatch({ type: 'SUBMIT_FULFILLED', returnRequest });
    } catch (error) {
      dispatch({ type: 'SUBMIT_REJECTED', error });
    }
  };

  const update = async (items: ItemToSubmit[]) => {
    if (!returnRequestId) {
      return;
    }

    dispatch({ type: 'SUBMIT_PENDING' });

    try {
      const returnRequest = await updateReturn(returnRequestId, { items });
      dispatch({ type: 'SUBMIT_FULFILLED', returnRequest });
    } catch (error) {
      dispatch({ type: 'SUBMIT_REJECTED', error });
    }
  };

  return { submitState, create, update };
}

export default useReturnRequestActions;
