import React from 'react';
import { useTranslation } from 'react-i18next';

import PageView from '../PageView';
import ConfirmationContactUs from './ConfirmationContactUs';
import ConfirmationProductsList from './ConfirmationProductsList';
import { ReturnConfirmationViewProps } from './types';

function PendingConfirmationView(props: ReturnConfirmationViewProps) {
  const { returnRequest } = props;
  const { t } = useTranslation();

  const message = t('confirmationPending_message', { customerEmail: returnRequest.customerEmail });

  return (
    <PageView heading="confirmationPending_heading" message={message} intent="success">
      <ConfirmationContactUs />
      <ConfirmationProductsList heading="You are returning:" items={returnRequest.items} />
    </PageView>
  );
}

export default React.memo(PendingConfirmationView);
